const GET_NEWS = 'GET_NEWS';
const NEWS_READY = 'NEWS_READY';

const START_LOADING_NEWS = 'START_LOADING_NEWS';
const STOP_LOADING_NEWS = 'STOP_LOADING_NEWS';

const startLoadingNews = () => ({
	type: START_LOADING_NEWS
});

const stopLoadingNews = () => ({
	type: STOP_LOADING_NEWS
});

const getNews = (payload) => ({
	type: GET_NEWS,
	payload
});

const newsDataReady = (payload) => ({
	type: NEWS_READY,
	payload
});

export {
	GET_NEWS,
	NEWS_READY,
	START_LOADING_NEWS,
	STOP_LOADING_NEWS,
	getNews,
	newsDataReady,
	startLoadingNews,
	stopLoadingNews,
};