import {
	TEAM_READY,
	GET_TEAM,
	START_LOADING_TEAM,
	STOP_LOADING_TEAM
} from '../actions/teams.actions';

const initialState = {
	isLoading: true,
};

const teamsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_TEAM:
		return state;
	case TEAM_READY:
		return {
			...state,
			...action.payload 
		};
	case START_LOADING_TEAM:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_TEAM:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};

export default teamsReducer;