import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	newsDataReady,
	GET_NEWS,
	stopLoadingNews,
	startLoadingNews
} from '../actions/news.actions';

const getNewsMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_NEWS) {
		dispatch(startLoadingNews());

		try {
			const response  = await httpProvider.get('news');
			
			if (response.data) {
				const newsState = response.data;
				dispatch(newsDataReady(newsState));
			}
		} catch (err) {
			dispatch(newsDataReady({}));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingNews());
		}
	} else {
		return next(action);
	}
};

export { getNewsMiddleware };