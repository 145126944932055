const GET_STANDINGS = 'GET_STANDINGS';
const STANDINGS_READY = 'STANDINGS_READY';

const START_LOADING_STANDINGS = 'START_LOADING_STANDINGS';
const STOP_LOADING_STANDINGS = 'STOP_LOADING_STANDINGS';

const SET_CURRENT_TOURNAMENT = 'SET_CURRENT_TOURNAMENT';

const startLoadingStandings = () => ({
	type: START_LOADING_STANDINGS
});

const stopLoadingStandings = () => ({
	type: STOP_LOADING_STANDINGS
});

const getStandings = (tournamentId) => ({
	type: GET_STANDINGS,
	payload: tournamentId
});

const standingsReady = (data) => ({
	type: STANDINGS_READY,
	payload: data
});

const setCurrentTournament = (data) => {
	return {
		type: SET_CURRENT_TOURNAMENT,
		payload: data
	};
};

export {
	getStandings,
	standingsReady,
	GET_STANDINGS,
	STANDINGS_READY,
	startLoadingStandings,
	stopLoadingStandings,
	START_LOADING_STANDINGS,
	STOP_LOADING_STANDINGS,
	setCurrentTournament,
	SET_CURRENT_TOURNAMENT
};