import {
	GET_STANDINGS,
	SET_CURRENT_TOURNAMENT,
	STANDINGS_READY,
	START_LOADING_STANDINGS,
	STOP_LOADING_STANDINGS
} from '../actions/standings.actions';

const initialState = {
	isLoading: true,
	currentTournament: '172'
};

const standingsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_STANDINGS:
		return state;
	case STANDINGS_READY:
		return {
			...state,
			...action.payload 
		};
	case START_LOADING_STANDINGS:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_STANDINGS:
		return {
			...state,
			isLoading: false
		};
	case SET_CURRENT_TOURNAMENT:
		return {
			...state,
			currentTournament: action.payload
		};
	default:
		return state;
	}
};

export default standingsReducer;