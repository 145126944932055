import { toast } from 'react-toastify';
import { findTournament } from '../../common/constants';
import httpProvider from '../../utils/httpProvider';
import {
	standingsReady,
	GET_STANDINGS,
	stopLoadingStandings,
	startLoadingStandings,
} from '../actions/standings.actions';

const getStandingsMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_STANDINGS) {
		dispatch(startLoadingStandings());

		try {
			const tournamentId = action.payload;

			const response = await httpProvider.get(`standings/${tournamentId}`);
			const tournament = findTournament(tournamentId);

			if (response.data) {
				const newState = {
					[`${tournament.tournamentId}`]: {
						...response.data,
						countryBg: tournament?.countryBg,
						tournamentNameBg: tournament?.tournamentNameBg,
					},
				};
	
				dispatch(standingsReady(newState));
			}
		} catch (err) {
			dispatch(standingsReady({}));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingStandings());
		}
	} else {
		return next(action);
	}
};

export { getStandingsMiddleware };
