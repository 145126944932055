const GET_USERS_GAME_DATA = 'GET_USERS_GAME_DATA';
const SET_USERS_GAME_DATA = 'SET_USERS_GAME_DATA';
const GET_LOGGED_USER = 'GET_LOGGED_USER';
const SET_LOGGED_USER = 'SET_LOGGED_USER';
const SET_LOGGED_USER_DAILY_VOTES = 'SET_LOGGED_USER_DAILY_VOTES';
const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
const CHANGE_FORGOTTEN_USER_PASSWORD = 'CHANGE_FORGOTTEN_USER_PASSWORD';
const SEND_USER_FORGOTTEN_PASSWORD_EMAIL = 'SEND_USER_FORGOTTEN_PASSWORD_EMAIL';
const DELETE_USER = 'DELETE_USER';
const REGISTER_USER = 'REGISTER_USER';
const START_LOADING_USERS = 'START_LOADING_USERS';
const STOP_LOADING_USERS = 'STOP_LOADING_USERS';
const START_LOGIN_LOADING = 'START_LOGIN_LOADING';
const STOP_LOGIN_LOADING = 'STOP_LOGIN_LOADING';
const START_LOADING_USERS_GAME_DATA = 'START_LOADING_USERS_GAME_DATA';
const STOP_LOADING_USERS_GAME_DATA = 'STOP_LOADING_USERS_GAME_DATA';
const TAKE_MISSION_COINS = 'TAKE_MISSION_COINS';
const SET_USER_MISSIONS = 'SET_USER_MISSIONS';

const getUsersGameData = () => ({
	type: GET_USERS_GAME_DATA,
});

const getLoggedUser = () => ({
	type: GET_LOGGED_USER
});

const deleteUser = (payload) => ({
	type: DELETE_USER,
	payload
});

const changeUserPassword = (payload) => ({
	type: CHANGE_USER_PASSWORD,
	payload
});

const changeForgottenUserPassword = (payload) => ({
	type: CHANGE_FORGOTTEN_USER_PASSWORD,
	payload
});

const sendUserForgottenPasswordEmail = (payload) => ({
	type: SEND_USER_FORGOTTEN_PASSWORD_EMAIL,
	payload
});

const setLoggedUser = (payload) => ({
	type: SET_LOGGED_USER,
	payload
});

const setLoggedUserDailyVotes = (payload) => ({
	type: SET_LOGGED_USER_DAILY_VOTES,
	payload
});

const getProfileData = (userId) => ({
	type: GET_PROFILE_DATA,
	payload: userId
});

const setProfileData = (payload) => ({
	type: SET_PROFILE_DATA,
	payload
});

const setUsersGameData = (payload) => ({
	type: SET_USERS_GAME_DATA,
	payload
});

const setUserMissions = (payload) => ({
	type: SET_USER_MISSIONS,
	payload
});

const registerUser = (payload) => ({
	type: REGISTER_USER,
	payload
});

const startLoadingUsers= () => ({
	type: START_LOADING_USERS
});

const stopLoadingUsers = () => ({
	type: STOP_LOADING_USERS
});

const startLoginLoading = () => ({
	type: START_LOGIN_LOADING
});

const stopLoginLoading = () => ({
	type: STOP_LOGIN_LOADING
});

const startLoadingUsersGameData = () => ({
	type: START_LOADING_USERS_GAME_DATA
});

const stopLoadingUsersGameData = () => ({
	type: STOP_LOADING_USERS_GAME_DATA
});

const takeMissionCoins = (payload) => ({
	type: TAKE_MISSION_COINS,
	payload,
});

export {
	GET_USERS_GAME_DATA,
	REGISTER_USER,
	START_LOADING_USERS,
	STOP_LOADING_USERS,
	START_LOGIN_LOADING,
	STOP_LOGIN_LOADING,
	GET_PROFILE_DATA,
	SET_PROFILE_DATA,
	GET_LOGGED_USER,
	SET_LOGGED_USER,
	DELETE_USER,
	CHANGE_USER_PASSWORD,
	CHANGE_FORGOTTEN_USER_PASSWORD,
	SEND_USER_FORGOTTEN_PASSWORD_EMAIL,
	SET_USERS_GAME_DATA,
	SET_LOGGED_USER_DAILY_VOTES,
	START_LOADING_USERS_GAME_DATA,
	STOP_LOADING_USERS_GAME_DATA,
	TAKE_MISSION_COINS,
	SET_USER_MISSIONS,
	changeUserPassword,
	changeForgottenUserPassword,
	sendUserForgottenPasswordEmail,
	getUsersGameData,
	setUsersGameData,
	registerUser,
	startLoadingUsers,
	stopLoadingUsers,
	startLoginLoading,
	stopLoginLoading,
	getProfileData,
	setProfileData,
	getLoggedUser,
	setLoggedUser,
	deleteUser,
	setLoggedUserDailyVotes,
	startLoadingUsersGameData,
	stopLoadingUsersGameData,
	takeMissionCoins,
	setUserMissions,
};