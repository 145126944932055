const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SET_AUTH_FORM = 'SET_AUTH_FORM';
const START_LOADING_AUTH = 'START_LOADING_AUTH';
const STOP_LOADING_AUTH = 'STOP_LOADING_AUTH';

const setAuthForm = (form) => ({
	type: SET_AUTH_FORM,
	payload: form
});

const login = (payload) => ({
	type: LOGIN,
	payload
});

const logout = (payload) => ({
	type: LOGOUT,
	payload
});

const startLoadingAuth = () => ({
	type: START_LOADING_AUTH
});

const stopLoadingAuth = () => ({
	type: STOP_LOADING_AUTH
});

export {
	LOGIN,
	START_LOADING_AUTH,
	STOP_LOADING_AUTH,
	SET_AUTH_FORM,
	LOGOUT,
	login,
	logout,
	startLoadingAuth,
	stopLoadingAuth,
	setAuthForm
};