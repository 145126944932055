import { quizesPageFilters } from '../../common/constants';
import {
	QUIZES_READY,
	GET_QUIZES,
	START_LOADING_QUIZES,
	STOP_LOADING_QUIZES,
	SET_QUIZ,
	START_LOADING_QUIZ_ANSWERES,
	STOP_LOADING_QUIZ_ANSWERES,
	CHANGE_PAGE_FILTER,
	START_LOADING_QUIZ_STANDINGS,
	STOP_LOADING_QUIZ_STANDINGS,
	QUIZ_STANDINGS_READY
} from '../actions/quizes.actions';

const initialState = {
	isLoading: true,
	quizes: null,
	quiz: null,
	isAnsweresLoading: false,
	pageFilter: quizesPageFilters[0],
	standings: null,
	isStandingsLoading: false
};

const quizesReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_QUIZES:
		return state;
	case QUIZES_READY:
		return {
			...state,
			quizes: action.payload 
		};
	case SET_QUIZ:
		return {
			...state,
			quiz: action.payload 
		};
	case CHANGE_PAGE_FILTER:
		return {
			...state,
			pageFilter: action.payload
		};
	case START_LOADING_QUIZES:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_QUIZES:
		return {
			...state,
			isLoading: false
		};
	case START_LOADING_QUIZ_ANSWERES: 
		return {
			...state,
			isAnsweresLoading: true
		};
	case STOP_LOADING_QUIZ_ANSWERES:
		return {
			...state,
			isAnsweresLoading: false
		};
	case START_LOADING_QUIZ_STANDINGS:
		return {
			...state,
			isStandingsLoading: true
		};
	case STOP_LOADING_QUIZ_STANDINGS:
		return {
			...state,
			isStandingsLoading: false
		};
	case QUIZ_STANDINGS_READY:
		return {
			...state,
			standings: action.payload
		};
	default:
		return state;
	}
};

export default quizesReducer;