import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	fixtureDetailsReady,
	GET_FIXTURE_DETAILS,
	startLoadingFixtureDetails,
	startLoadingVoting,
	stopLoadingFixtureDetails,
	stopLoadingVoting,
	VOTE_FOR_FIXTURE
} from '../actions/fixture-details.actions';
import { isCurrentTimeBetween0000And0005 } from '../../utils/utils';
import { setLoggedUserDailyVotes, setUsersGameData } from '../actions/users.actions';

const fetchFixtureById = async (fixtureId, userId) => {
	const query = userId ? `?user=${userId}` : '';
	const response  = await httpProvider.get(`predictions/${fixtureId}${query}`);

	return response;
};

const getFixtureDetailsMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === GET_FIXTURE_DETAILS) {
		dispatch(startLoadingFixtureDetails());

		try {
			const response = await fetchFixtureById(action.payload, getState()?.users?.loggedUser?.id);
  
			if (response.data) {
				const fixtureDetailsState = {
					[`${action.payload}`]: response.data
				};

				dispatch(fixtureDetailsReady(fixtureDetailsState));
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error('Такъв мач не съществува!');
			}
		} finally {
			dispatch(stopLoadingFixtureDetails());
		}
	} else {
		return next(action);
	}
};

const voteForFixtureMiddleware = ({ dispatch, getState }) => next => async action => {
	if(action.type === VOTE_FOR_FIXTURE) {
		const cannotVote = isCurrentTimeBetween0000And0005();
		if (cannotVote) {
			toast.info('Моля опитайте да гласувате по-късно.');
			return;
		}

		dispatch(startLoadingVoting());

		try {
			const response  = await httpProvider.patch(`fixtures/votes/${action.payload.fixtureId}`, {
				userId: getState().users.loggedUser.id,
				vote: action.payload.vote
			}, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
  
			if (response.data) {
				const newDailyVotes = response.data.newDailyVotes;
				const allowedVotes = 10;

				const fixtureByIdResponse =
					await fetchFixtureById(action.payload.fixtureId, getState()?.users?.loggedUser?.id);

				if (fixtureByIdResponse.data) {
					const fixtureDetailsState = {
						[`${action.payload.fixtureId}`]: fixtureByIdResponse.data
					};

					dispatch(fixtureDetailsReady(fixtureDetailsState));
				}
				dispatch(setLoggedUserDailyVotes(newDailyVotes));
				dispatch(setUsersGameData(null));

				if (newDailyVotes < allowedVotes) {
					toast.success(`Гласувахте успешно! Остават ви ${allowedVotes - newDailyVotes} прогнози за играта.`);
				} else if (newDailyVotes === allowedVotes) {
					toast.success('Гласувахте успешно! Поздравления изпълнихте всички прогнози за играта!');
				} else if (newDailyVotes > allowedVotes) {
					toast.success('Гласувахте успешно!');
				}
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingVoting());
		}
	} else {
		return next(action);
	}
};

export {
	getFixtureDetailsMiddleware,
	voteForFixtureMiddleware
};