const GET_FIXTURES = 'GET_FIXTURES';
const GET_TOP_FIXTURES = 'GET_TOP_FIXTURES';
const TOP_FIXTURES_READY = 'TOP_FIXTURES_READY';
const FIXTURES_READY = 'FIXTURES_READY';
const FACTS_READY = 'FACTS_READY';
const CHANGE_FIXTURES_CURRENT_DAY = 'CHANGE_FIXTURES_CURRENT_DAY';
const GET_EC_FIXTURES = 'GET_EC_FIXTURES';
const EC_FIXTURES_READY = 'EC_FIXTURES_READY';

const START_LOADING_FIXTURES = 'START_LOADING_FIXTURES';
const STOP_LOADING_FIXTURES = 'STOP_LOADING_FIXTURES';

const getFixtures = (date) => ({
	type: GET_FIXTURES,
	payload: date
});

const changeFixturesCurrentDay = (day) => ({
	type: CHANGE_FIXTURES_CURRENT_DAY,
	payload: day
});

const getEcFixtures = (date) => ({
	type: GET_EC_FIXTURES,
	payload: date
});

const getTopFixtures = (date) => ({
	type: GET_TOP_FIXTURES,
	payload: date
});

const fixturesReady = (data) => ({
	type: FIXTURES_READY,
	payload: data
});

const factsReady = (data) => ({
	type: FACTS_READY,
	payload: data
});

const topFixturesReady = (data) => ({
	type: TOP_FIXTURES_READY,
	payload: data
});

const ecFixturesReady = (data) => ({
	type: EC_FIXTURES_READY,
	payload: data
});

const startLoadingFixtures = () => ({
	type: START_LOADING_FIXTURES
});

const stopLoadingFixtures = () => ({
	type: STOP_LOADING_FIXTURES
});

export {
	ecFixturesReady,
	getFixtures,
	getTopFixtures,
	fixturesReady,
	topFixturesReady,
	changeFixturesCurrentDay,
	CHANGE_FIXTURES_CURRENT_DAY,
	EC_FIXTURES_READY,
	GET_FIXTURES,
	GET_TOP_FIXTURES,
	FIXTURES_READY,
	TOP_FIXTURES_READY,
	FACTS_READY,
	GET_EC_FIXTURES,
	START_LOADING_FIXTURES,
	STOP_LOADING_FIXTURES,
	factsReady,
	startLoadingFixtures,
	stopLoadingFixtures,
	getEcFixtures,
};