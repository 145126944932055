import {
	NEWS_READY,
	GET_NEWS,
	START_LOADING_NEWS,
	STOP_LOADING_NEWS
} from '../actions/news.actions';

const initialState = {
	isLoading: true,
	news: null,
};

const newsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_NEWS:
		return state;
	case NEWS_READY:
		return {
			...state,
			data: action.payload 
		};
	case START_LOADING_NEWS:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_NEWS:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};

export default newsReducer;