import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	quizesReady,
	GET_QUIZES,
	stopLoadingQuizes,
	startLoadingQuizes,
	GET_QUIZ_BY_ID,
	setQuiz,
	SAVE_QUIZ_ANSWERS,
	startLoadingQuizAnsweres,
	stopLoadingQuizAnsweres,
	INCREMENT_QUIZ_TRIES,
	GET_QUIZ_STANDINGS,
	startLoadingQuizStandings,
	stopLoadingQuizStandings,
	quizStandingsReady
} from '../actions/quizes.actions';

export const getQuizesMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_QUIZES) {
		dispatch(startLoadingQuizes());

		try {
			const url = action.payload?.userId ? `quizes?userId=${action.payload.userId}` : 'quizes';
			const response  = await httpProvider.get(url);

			if (response.data) {
				dispatch(quizesReady(response.data));
			}
		} catch (err) {
			dispatch(quizesReady(null));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingQuizes());
		}
	} else {
		return next(action);
	}
};

export const getQuizStandingsMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_QUIZ_STANDINGS) {
		dispatch(startLoadingQuizStandings());

		try {
			const response  = await httpProvider.get('quizes/standings');

			if (response.data) {
				dispatch(quizStandingsReady(response.data));
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingQuizStandings());
		}
	} else {
		return next(action);
	}
};

export const incrementQuizTriesMiddleware = () => next => async action => {
	if (action.type === INCREMENT_QUIZ_TRIES) {

		try {
			await httpProvider.put(`quizes/${action.payload}/tries`);
		} catch (err) {
			// the user shoiuld not be notified for this error
		}
	} else {
		return next(action);
	}
};

export const getQuizByIdMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_QUIZ_BY_ID) {
		dispatch(startLoadingQuizes());

		try {
			const url = action.payload.userId ?
				`quizes/${action.payload.quizId}?userId=${action.payload.userId}` :
				`quizes/${action.payload.quizId}`;
			const response  = await httpProvider.get(url);

			if (response.data) {
				dispatch(setQuiz(response.data));
			}
		} catch (err) {
			dispatch(setQuiz(null));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingQuizes());
		}
	} else {
		return next(action);
	}
};

export const saveQuizAnswersMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === SAVE_QUIZ_ANSWERS) {
		dispatch(startLoadingQuizAnsweres());

		try {
			const response  = await httpProvider.post('quizes/submissions', action.payload, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});

			if (response.data) {
				const url = action.payload?.userId ? `quizes?userId=${action.payload.userId}` : 'quizes';
				const quizesResponse  = await httpProvider.get(url);

				if (quizesResponse.data) {
					dispatch(quizesReady(quizesResponse.data));
				}
			}
		} catch (err) {
			toast.success('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingQuizAnsweres());
		}
	} else {
		return next(action);
	}
};