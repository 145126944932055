import {
	SET_AUTH_FORM,
	START_LOADING_AUTH,
	STOP_LOADING_AUTH
} from '../actions/auth.actions';

const initialState = {
	isLoading: false,
	user: null,
	authForm: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_AUTH_FORM:
		return {
			...state,
			authForm: action.payload
		};
	case START_LOADING_AUTH:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_AUTH:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};

export default authReducer;