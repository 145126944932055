const GET_QUIZES = 'GET_QUIZES';
const QUIZES_READY = 'QUIZES_READY';
const GET_QUIZ_BY_ID = 'GET_QUIZ_BY_ID';
const GET_QUIZ_STANDINGS = 'GET_QUIZ_STANDINGS';
const QUIZ_STANDINGS_READY = 'QUIZ_STANDINGS_READY';
const SET_QUIZ = 'SET_QUIZ';
const SAVE_QUIZ_ANSWERS = 'SAVE_QUIZ_ANSWERS';
const CHANGE_PAGE_FILTER = 'CHANGE_PAGE_FILTER';
const INCREMENT_QUIZ_TRIES = 'INCREMENT_QUIZ_TRIES';

const START_LOADING_QUIZES = 'START_LOADING_QUIZES';
const STOP_LOADING_QUIZES = 'STOP_LOADING_QUIZES';

const START_LOADING_QUIZ_ANSWERES = 'START_LOADING_QUIZ_ANSWERES';
const STOP_LOADING_QUIZ_ANSWERES = 'STOP_LOADING_QUIZ_ANSWERES';

const START_LOADING_QUIZ_STANDINGS = 'START_LOADING_QUIZ_STANDINGS';
const STOP_LOADING_QUIZ_STANDINGS = 'STOP_LOADING_QUIZ_STANDINGS';

const getQuizStandings = () => ({
	type: GET_QUIZ_STANDINGS,
});

const startLoadingQuizStandings = () => ({
	type: START_LOADING_QUIZ_STANDINGS
});

const stopLoadingQuizStandings = () => ({
	type: STOP_LOADING_QUIZ_STANDINGS
});

const startLoadingQuizes = () => ({
	type: START_LOADING_QUIZES
});

const stopLoadingQuizes = () => ({
	type: STOP_LOADING_QUIZES
});

const startLoadingQuizAnsweres = () => ({
	type: START_LOADING_QUIZ_ANSWERES
});

const stopLoadingQuizAnsweres = () => ({
	type: STOP_LOADING_QUIZ_ANSWERES
});

const incrementQuizTries = (payload) => ({
	type: INCREMENT_QUIZ_TRIES,
	payload
});

const getQuizes = (payload) => ({
	type: GET_QUIZES,
	payload
});

const changePageFilter = (payload) => ({
	type: CHANGE_PAGE_FILTER,
	payload
});

const saveQuizAnswers = (payload) => ({
	type: SAVE_QUIZ_ANSWERS,
	payload
});

const setQuiz = (payload) => ({
	type: SET_QUIZ,
	payload
});

const getQuizById = (payload) => ({
	type: GET_QUIZ_BY_ID,
	payload
});

const quizesReady = (payload) => ({
	type: QUIZES_READY,
	payload
});

const quizStandingsReady = (payload) => ({
	type: QUIZ_STANDINGS_READY,
	payload
});

export {
	GET_QUIZES,
	QUIZES_READY,
	START_LOADING_QUIZES,
	STOP_LOADING_QUIZES,
	GET_QUIZ_BY_ID,
	SET_QUIZ,
	SAVE_QUIZ_ANSWERS,
	START_LOADING_QUIZ_ANSWERES,
	STOP_LOADING_QUIZ_ANSWERES,
	CHANGE_PAGE_FILTER,
	INCREMENT_QUIZ_TRIES,
	GET_QUIZ_STANDINGS,
	START_LOADING_QUIZ_STANDINGS,
	STOP_LOADING_QUIZ_STANDINGS,
	QUIZ_STANDINGS_READY,
	getQuizStandings,
	startLoadingQuizStandings,
	stopLoadingQuizStandings,
	incrementQuizTries,
	saveQuizAnswers,
	quizStandingsReady,
	setQuiz,
	getQuizById,
	getQuizes,
	quizesReady,
	startLoadingQuizes,
	stopLoadingQuizes,
	startLoadingQuizAnsweres,
	stopLoadingQuizAnsweres,
	changePageFilter
};