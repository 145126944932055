import { format } from 'date-fns';

import {
	FIXTURES_READY,
	GET_FIXTURES,
	START_LOADING_FIXTURES,
	STOP_LOADING_FIXTURES,
	TOP_FIXTURES_READY,
	CHANGE_FIXTURES_CURRENT_DAY,
	FACTS_READY,
	EC_FIXTURES_READY
} from '../actions/fixtures.actions';

const initialState = {
	isLoading: true,
	currentDay: format(new Date(), 'dd-MM-yyyy'),
	facts: null,
};

const fixturesReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_FIXTURES:
		return state;
	case FIXTURES_READY:
		return {
			...state,
			...action.payload 
		};
	case TOP_FIXTURES_READY:
		return {
			...state,
			...action.payload 
		};
	case EC_FIXTURES_READY:
		return {
			...state,
			...action.payload 
		};
	case FACTS_READY:
		return {
			...state,
			facts: action.payload 
		};
	case CHANGE_FIXTURES_CURRENT_DAY:
		return {
			...state,
			currentDay: action.payload 
		};
	case START_LOADING_FIXTURES:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_FIXTURES:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};

export default fixturesReducer;