const GET_TEAM = 'GET_TEAM';
const TEAM_READY = 'TEAM_READY';

const START_LOADING_TEAM = 'START_LOADING_TEAM';
const STOP_LOADING_TEAM = 'STOP_LOADING_TEAM';

const startLoadingTeam = () => ({
	type: START_LOADING_TEAM
});

const stopLoadingTeam = () => ({
	type: STOP_LOADING_TEAM
});

const getTeam = (payload) => ({
	type: GET_TEAM,
	payload
});

const teamDataReady = (payload) => ({
	type: TEAM_READY,
	payload
});

export {
	GET_TEAM,
	TEAM_READY,
	START_LOADING_TEAM,
	STOP_LOADING_TEAM,
	getTeam,
	teamDataReady,
	startLoadingTeam,
	stopLoadingTeam,
};