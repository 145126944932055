const GET_FIXTURE_DETAILS = 'GET_FIXTURE_DETAILS';
const FIXTURE_DETAILS_READY = 'FIXTURE_DETAILS_READY';
const RESET_FIXTURE_DETAILS = 'RESET_FIXTURE_DETAILS';
const START_LOADING_FIXTURE_DETAILS = 'START_LOADING_FIXTURE_DETAILS';
const STOP_LOADING_FIXTURE_DETAILS = 'STOP_LOADING_FIXTURE_DETAILS';
const START_LOADING_VOTING = 'START_LOADING_VOTING';
const STOP_LOADING_VOTING = 'STOP_LOADING_VOTING';
const VOTE_FOR_FIXTURE = 'VOTE_FOR_FIXTURE';

const voteForFixture = (fixtureId, vote ) => ({
	type: VOTE_FOR_FIXTURE,
	payload: { fixtureId, vote }
});

const resetFixtureDetails = () => ({
	type: RESET_FIXTURE_DETAILS
});

const startLoadingFixtureDetails = () => ({
	type: START_LOADING_FIXTURE_DETAILS
});

const stopLoadingFixtureDetails = () => ({
	type: STOP_LOADING_FIXTURE_DETAILS
});

const startLoadingVoting = () => ({
	type: START_LOADING_VOTING
});

const stopLoadingVoting = () => ({
	type: STOP_LOADING_VOTING
});

const getFixtureDetails = (payload) => ({
	type: GET_FIXTURE_DETAILS,
	payload
});

const fixtureDetailsReady = (payload) => ({
	type: FIXTURE_DETAILS_READY,
	payload
});

export {
	getFixtureDetails,
	fixtureDetailsReady,
	startLoadingFixtureDetails,
	stopLoadingFixtureDetails,
	resetFixtureDetails,
	startLoadingVoting,
	stopLoadingVoting,
	GET_FIXTURE_DETAILS,
	FIXTURE_DETAILS_READY,
	START_LOADING_FIXTURE_DETAILS,
	RESET_FIXTURE_DETAILS,
	STOP_LOADING_FIXTURE_DETAILS,
	VOTE_FOR_FIXTURE,
	START_LOADING_VOTING,
	STOP_LOADING_VOTING,
	voteForFixture
};