import axios from 'axios';

export const API_URL = process.env.GATSBY_API_URL || 'https://futbolbro-api.com/';

/**
  axios init with default settings (remmembers token, url and etc.)
 */
const axiosWithDefaultOptions = (options) => {
	const defaultOptions = {
		withCredentials: true,
		baseURL: API_URL,
	};
	return axios.create({ ...defaultOptions, ...options });
};

const get = (endpoint, options = {}) =>
	axiosWithDefaultOptions(options).get(endpoint);

const post = (endpoint, data = {}, options = {}) =>
	axiosWithDefaultOptions(options).post(endpoint, data, options);

const put = (endpoint, data = {}, options = {}) =>
	axiosWithDefaultOptions(options).put(endpoint, data, options);

const patch = (endpoint, data = {}, options = {}) =>
	axiosWithDefaultOptions(options).patch(endpoint, data, options);

// delete
const remove = (endpoint, data = {}, options = {}) =>
	axiosWithDefaultOptions(options).delete(endpoint, data, options);

/**
  Wrapper over axios methods using initial settings
  get, post, patch and remove(delete)
*/
const httpProvider = {
	get,
	post,
	patch,
	remove,
	put
};

export default httpProvider;
